
import { defineComponent, PropType } from "vue";

type toastType = "success" | "error" | "warning" | "default";

export default defineComponent({
  name: "Toast",
  props: {
    type: {
      type: String as PropType<toastType>,
      default: "success",
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    modal: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 2000,
    },
    onClose: Function,
  },
  setup(props) {
    const toastType = {
      success: require("./imgs/success.png"),
      error: require("./imgs/error.png"),
      warning: require("./imgs/warning.png"),
      default: "",
    };
    const toastIcon = props.icon ? props.icon : toastType[props.type];
    return {
      toastIcon,
    };
  },
});
