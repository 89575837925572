import axios, { AxiosRequestConfig } from 'axios';

import Toast from '@/components/UI/Toast'

import router from '@/router'


// const baseURL = process.env.NODE_ENV === 'production' ? 'http://123.57.42.144:9099' : '/'
const baseURL = process.env.NODE_ENV === 'production' ? '/' : '/'
//  create an instance


axios.defaults.withCredentials = true;

const api = axios.create({
    baseURL,
    timeout: 10000,
})

api.defaults.withCredentials = true;

const requestQueue = new Set();

const cancelQueue = {}

const CancelToken = axios.CancelToken;

function createRequestToken(config: AxiosRequestConfig) {
    const url = config.url ? JSON.stringify(config.url) : '';
    const method = config.method ? JSON.stringify(config.method) : '';
    const data = config.data ? JSON.stringify(config.data) : '';
    const params = config.params ? JSON.stringify(config.params) : '';

    return url + method + data + params
}


// Add a request interceptor
api.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = localStorage.getItem('token') || '';
    if (config.params && config.params.validate && !token) {
        Toast({
            type: 'warning',
            title: "请先登录",
            onClose: () => {
                router.push({
                    path: "/login",
                    query: {
                        redirect: router.currentRoute.value.fullPath
                    }
                })
            },
        });
        throw new Error("请先登录，再执行此操作!");
    }
    //  取消重复请求
    const request = createRequestToken(config)

    config.cancelToken = new CancelToken((cancel) => {
        cancelQueue[request] = cancel
    })
    if (requestQueue.has(request)) {
        cancelQueue[request]('请求过于频繁，请稍后再试')
    } else {
        requestQueue.add(request)
    }
    config.headers = Object.assign(config.headers, { token })
    return config;
}, function (error) {
    return Promise.reject(error);
})


interface ErrorObject {
    msg: string;
    status: number;
    returnUrl?: string;
}

// Add a response interceptor
api.interceptors.response.use(function (response) {

    const request = createRequestToken(response.config)
    requestQueue.delete(request)
    return response


}, function (error) {
    if (axios.isCancel(error)) {
        console.log("重复请求！")
    }
    return Promise.reject(error);
});




export default api