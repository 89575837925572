<template>
  <div class="toast">
    <img v-if="toastIcon" :src="toastIcon" alt="" />
    {{ title }}
  </div>
  <div v-if="modal" class="toast-modal"></div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

type toastType = "success" | "error" | "warning" | "default";

export default defineComponent({
  name: "Toast",
  props: {
    type: {
      type: String as PropType<toastType>,
      default: "success",
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    modal: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 2000,
    },
    onClose: Function,
  },
  setup(props) {
    const toastType = {
      success: require("./imgs/success.png"),
      error: require("./imgs/error.png"),
      warning: require("./imgs/warning.png"),
      default: "",
    };
    const toastIcon = props.icon ? props.icon : toastType[props.type];
    return {
      toastIcon,
    };
  },
});
</script>

<style lang="scss" scoped>
.toast-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.toast {
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  min-height: 50px;
  max-width: 300px;
  font-size: 14px;
  text-align: center;
  img {
    width: 30px;
    height: 30px;

    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
  }
}
</style>

